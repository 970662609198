<template>
  <div>
    <svg
      width="350"
      height="238"
      viewBox="0 0 350 238"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M291.241 208.876C307.26 208.876 320.22 195.77 320.22 179.752L320.365 34.1311C320.365 17.9672 307.26 5.00696 291.241 5.00696H58.2482C42.0844 5.00696 29.1241 17.9672 29.1241 34.1311V179.752C29.1241 195.77 42.0844 208.876 58.2482 208.876H0V238H349.49V208.876H291.241ZM58.2482 179.752V34.1311H291.241V179.752V179.897L58.2482 179.752Z"
        fill="#E7E5F1"
      />
      <path
        d="M219.557 55.7503H216.089V44.8127C216.089 21.9897 197.568 3.46875 174.745 3.46875C151.922 3.46875 133.401 21.9897 133.401 44.8127V55.7503H129.932C119.507 55.7503 110.994 64.2631 110.994 74.6879V149.376C110.994 159.801 119.507 168.313 129.932 168.313H219.557C229.982 168.313 238.495 159.801 238.495 149.376V74.6879C238.495 64.2631 229.982 55.7503 219.557 55.7503ZM174.745 122.969C168.738 122.969 163.807 118.038 163.807 112.032C163.807 106.025 168.738 101.094 174.745 101.094C180.751 101.094 185.682 106.025 185.682 112.032C185.682 118.038 180.751 122.969 174.745 122.969ZM193.898 55.7503H155.591V44.8127C155.591 34.2502 164.182 25.6595 174.745 25.6595C185.307 25.6595 193.898 34.2502 193.898 44.8127V55.7503Z"
        fill="#C4BDF3"
        stroke="white"
        stroke-width="8"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "ResetPasswordSVG",
};
</script>
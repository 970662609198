<template>
  <div>
    <CHeader light class="border-0 c-sticky-top">
      <nav
        class="navbar navbar-expand-lg navbar-light"
        style="padding-left: 11%; width: 100%"
      >
        <CRow class="login-header">
          <CIcon
            class="c-sidebar-brand-full mb-3"
            name="logo"
            size="custom-size"
            :height="45"
            viewBox="0 0 550 148"
            style="padding-right: 100px"
          />
        </CRow>
      </nav>
    </CHeader>
    <div class="signup-main">
      <CContainer class="container-form">
        <div class="left">
          <h1 class="section-title">
            {{ $t("FORGOT_PASSWORD.HEADER_MSG") }}
          </h1>
          <ResetPasswordSVG />
        </div>
        <div class="right">
          <form class="form">
            <CAlert color="primary" v-if="showPrimaryInfoAlert">
              <CIcon name="cil-check-circle" />&nbsp;&nbsp;{{ this.alertMsg }}
            </CAlert>
            <CAlert color="danger" v-if="showPrimaryErrorAlert">
              <CIcon name="cil-warning" />&nbsp;&nbsp;{{ this.alertMsg }}
            </CAlert>
            <div class="fullrow">
              <CInput
                :label="$t('LOGIN.LABEL_EMAIL')"
                type="email"
                :placeholder="$t('LOGIN.PLACEHOLDER_EMAIL')"
                class="col-12 p-0"
                v-model="username"
                required
                valid-feedback
                :invalid-feedback="$t('LOGIN.VALIDATION_EMAIL')"
                :is-valid="validateEmail"
              >
              </CInput>
            </div>
            <div class="rowcta mt-3">
              <CButton
                :disabled="!isActive"
                color="durianprimary"
                class="col-6 col-xl-6 px-4 mb-3 bigbutton"
                @click="submitEmail"
              >
                {{ $t("FORGOT_PASSWORD.SUBMIT_BUTTON") }}
              </CButton>
              <p>
                <span>{{ $t("LOGIN.BACK_TO_LOGIN") }}</span>
                <a @click="goTo('/login')">
                  {{ $t("SIGN_UP.SIGNUP") }}
                </a>
              </p>
            </div>
          </form>
        </div>
      </CContainer>
    </div>
  </div>
</template>

<script>
import { ForgotPassword } from "@/api/resetPassword.api.js";
import ResetPasswordSVG from "../ResetPasswordSVG";
export default {
  name: "ForgotPassword",
  components: {
    ResetPasswordSVG,
  },
  data() {
    return {
      username: null,
      isActive: true,
      showPrimaryErrorAlert: false,
      showPrimaryInfoAlert: false,
      alertMsg: "",
    };
  },
  methods: {
    async submitEmail() {
      const { username } = this;
      try {
        this.isActive = false;
        const response = await ForgotPassword(username);
        const err = this.verifyHttpResponse(response);
        if (err === false) {
          this.alertMsg = this.$t("RESET_PASSWORD.VERIFICATION_EMAIL_SENT");
          this.showPrimaryInfoAlert = true;
          this.showPrimaryErrorAlert = false;
        } else {
          this.isActive = true;
          this.alertMsg = this.$t("RESET_PASSWORD.RESET_FAILURE");
          this.showPrimaryErrorAlert = true;
          this.showPrimaryInfoAlert = false;
        }
      } catch (error) {
        this.isActive = false;
        this.isActive = true;
        this.alertMsg = this.$t("RESET_PASSWORD.RESET_FAILURE");
        this.showPrimaryErrorAlert = true;
        this.showPrimaryInfoAlert = false;
      }
    },
  },
};
</script>

import { httpClient } from './httpClient';

const RESETPASSWORD = '/dashboard/users/reset_password';
const FORGOTPASSWORD = '/dashboard/users/forgot_password';

const ResetPassword = async (token, newPassword, confirmNewPassword) => {
    const body = { 'token': token, 'password': newPassword, 'confirm_password': confirmNewPassword };
    const data = await httpClient.dashboardService.post(RESETPASSWORD, body);
    return data;
}

const ForgotPassword = async (emailId) => {
    const body = { 'email': emailId };
    const data = await httpClient.dashboardService.post(FORGOTPASSWORD, body);
    return data;
}

export { ResetPassword, ForgotPassword };